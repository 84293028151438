<template>
  <div class="meetingRoom_">
    <CSTabBar :checked-tab="listQuery.type" :tabbar="orderState" @changeTabBar="changePayStateTabBar"/>

    <!-- 在私有会议室下显示 -->
    <div
        v-show="listQuery.type === MEETING_ROOM_TYPE.PRIVATE"
    >
      <div class="filter-panel">
        <CSSelect style="margin-right: 20px; width: 185px;">
          <el-date-picker
              v-model="listQuery.reservationDate"
              :editable="false"
              format="yyyy-MM-dd "
              placeholder="请选择时间"
              prefix-icon="el-icon-time"
              style="width: 157px;"
              type="date"
              value-format="yyyy-MM-dd "
          >
          </el-date-picker>
        </CSSelect>
        <CSSelect style="margin-right: 30px; width: 155px;">
          <select
              v-model="listQuery.lesseeId"
              class="typeinput"
          >
            <option value="">全部公司</option>
            <option
                v-for="tenant in tenantList"
                :key="tenant.id"
                :value="tenant.id"
            >
              {{ tenant.companyName }}
            </option>
          </select>
        </CSSelect>
        <button class="btn btn-primary" @click="handleFilter()">
          查询
        </button>
      </div>

      <div class="result-panel">
        <CSTable :thead-top="60">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button
                  class="btn btn-primary sticky-right"
                  @click="openAddMeetingRoom"
              >
                <svg
                    aria-hidden="true"
                    class="icon"
                >
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加会议室
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>添加时间</th>
              <th>业主/租客</th>
              <th>会议室名称(房间号)</th>
              <th>使用人数</th>
              <th>预约时间段</th>
              <th>状态</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="(item, i) in list" :key="i">
              <td class="date-td">{{ item.createTime }}</td>
              <td>
                <div>
                  <p style="margin-bottom: 0">
                    {{
                      item.lesseeCompanyName
                    }}
                  </p>
                  <p style="margin-bottom: 0">
                    {{ item.lesseeName }}({{
                      item.lesseePhone
                    }})
                  </p>
                </div>
              </td>
              <td>
                <a
                    href="#"
                    style="color: #1DAFFF"
                    @click="
                                                        lookMeetingRoom(
                                                            item.type,
                                                            item.id,
                                                            item.roomName,
                                                            item.lesseeCompanyName
                                                        )
                                                    "
                >
                  {{ item.roomName }}</a
                >
              </td>
              <td>{{ item.capacity }}</td>
              <td>
                <template v-if="item.reservationTimeSection &&item.reservationTimeSection.length > 0">
                  {{
                    item.reservationTimeSection.join('、')
                  }}
                </template>
                <template v-else>-</template>
              </td>
              <td>
                {{
                  item.isEnable
                      ? "已上架"
                      : "已下架"
                }}
              </td>
              <td>
                <div class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                          @click="
                                                                    changeMeetingRoom(
                                                                        item
                                                                    )
                                                                "
                      >
                        修改会议室
                      </a>
                    </li>
                    <li>
                      <a
                          @click="
                                                                    deleteMeetingRoom(
                                                                        item.id
                                                                    )
                                                                "
                      >
                        删除会议室
                      </a>
                    </li>
                    <li
                        v-if="item.isEnable"
                    >
                      <a
                          @click="
                                                                    changeMeetingRoomEnableState(
                                                                        item.id,
                                                                        0
                                                                    )
                                                                "
                      >
                        下架会议室
                      </a>
                    </li>
                    <li v-else>
                      <a
                          @click="
                                                                    changeMeetingRoomEnableState(
                                                                        item.id,
                                                                        1
                                                                    )
                                                                "
                      >
                        上架会议室
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <Pagination></Pagination>
      </div>
    </div>

    <div
        v-show="listQuery.type === MEETING_ROOM_TYPE.PUBLIC"
    >
      <div class="filter-panel">
        <CSSelect style="margin-right: 30px; width: 185px;">
          <el-date-picker
              v-model="listQuery.reservationDate"
              :editable="false"
              format="yyyy-MM-dd"
              placeholder="请选择时间"
              prefix-icon="el-icon-time"
              style="width: 157px;"
              type="date"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </CSSelect>
        <button
            class="btn btn-primary"
            @click="handleFilter()"
        >
          查询
        </button>
      </div>

      <div class="result-panel">
        <CSTable :thead-top="60">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button
                  class="btn btn-primary sticky-right"
                  @click="openAddMeetingRoom"
              >
                <svg
                    aria-hidden="true"
                    class="icon"
                >
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加会议室
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>添加时间</th>
              <th>会议室名称(房间号)</th>
              <th>使用人数</th>
              <th>价格</th>
              <th>预约时间段</th>
              <th>状态</th>
              <th>用户查看次数</th>
              <th>用户预约次数</th>
              <th>用户使用次数</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="(item, i) in list" :key="i">
              <td class="date-td">{{ item.createTime }}</td>
              <td>
                                                <span
                                                    class="allow-click"
                                                    @click="
                                                        lookMeetingRoom(
                                                            item.type,
                                                            item.id,
                                                            item.roomName,
                                                            item.companyName
                                                        )
                                                    "
                                                >
                                                    {{ item.roomName }}
                                                </span>
              </td>
              <td>{{ item.capacity }}</td>
              <td>
                {{ item.price }}元/小时
              </td>
              <td>
                <template
                    v-if="item.reservationTimeSection && item.reservationTimeSection.length > 0"
                >
                  {{
                    item.reservationTimeSection.join('、')
                  }}
                </template>
                <template v-else>-</template>
              </td>
              <td>
                {{
                  item.isEnable
                      ? "已上架"
                      : "已下架"
                }}
              </td>
              <td>{{ item.userLookCount }}</td>
              <td>
                {{ item.userReservationCount }}
              </td>
              <td>{{ item.userUseCount }}</td>
              <td>
                <div class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                          @click="
                                                                    changeMeetingRoom(
                                                                        item
                                                                    )
                                                                "
                      >
                        修改会议室
                      </a>
                    </li>
                    <li>
                      <a
                          @click="
                                                                    deleteMeetingRoom(
                                                                        item.id
                                                                    )
                                                                "
                      >
                        删除会议室
                      </a>
                    </li>
                    <li
                        v-if="item.isEnable"
                    >
                      <a
                          @click="
                                                                    changeMeetingRoomEnableState(
                                                                        item.id,
                                                                        0
                                                                    )
                                                                "
                      >
                        下架会议室
                      </a>
                    </li>
                    <li v-else>
                      <a
                          @click="
                                                                    changeMeetingRoomEnableState(
                                                                        item.id,
                                                                        1
                                                                    )
                                                                "
                      >
                        上架会议室
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
        <Pagination></Pagination>
      </div>
    </div>

    <!-- 添加会议室的弹出框 -->

    <div v-show="!meetingRoomPreviewVisible">
      <CSDialog
          :dialogTitle="`${editMeetingRoom.id ? '修改' : '添加' }会议室`"
          :dialogVisible="meetingRoomModal"
          dialogWidth="960px"
          @onClose="meetingRoomModal = false"
          @onConfirm="previewEditMeetingRoomInfo"
      >
        <div
            slot="dialog-content"
            style="padding: 20px 30px; font-size: 24px;"
            @click="roomsPanelVisible = false"
        >
          <div style="margin-bottom: 13px;">
            <span class="lableBox">会议室名称(房间号)</span>
            <input
                v-model="editMeetingRoom.name"
                clearable
                placeholder="限10个字"
                style="border-radius: 4px; border: 1px solid #999; width: 400px; padding-left: 10px; height: 40px;"
            />
          </div>
          <div style="margin-bottom: 13px;">
            <span class="lableBox">使用人数</span>
            <i class="inputwidth">
              <input
                  v-model="minCapacity"
                  placeholder="1-99"
                  style="border-radius: 4px; border: 1px solid #999; width: 100px; padding-left: 10px; height: 40px;"
                  type="text"
              />
              -
              <input
                  v-model="maxCapacity"
                  placeholder="1-99"
                  style="border-radius: 4px; border: 1px solid #999; width: 100px; padding-left: 10px; height: 40px;"
                  type="text"
              />
            </i>
          </div>
          <div style="margin-bottom: 13px;">
                        <span class="lableBox">共享/私有会议室</span
                        ><i>
                            <span>
                                <input
                                    v-model="editMeetingRoom.type"
                                    :disabled="editMeetingRoom.id"
                                    :value="MEETING_ROOM_TYPE.PUBLIC"
                                    style="margin-right: 10px;margin-bottom: 10px;"
                                    type="radio"
                                />
                                <i class="inputSpace">共享会议室(收费)</i>
                            </span>
            <span>
                                <input
                                    v-model="editMeetingRoom.type"
                                    :disabled="editMeetingRoom.id"
                                    :value="MEETING_ROOM_TYPE.PRIVATE"
                                    style="margin: 0 10px 0 10px;"
                                    type="radio"
                                />
                                <i class="inputSpace">私有会议室(不收费)</i>
                            </span>
          </i>
          </div>
          <div
              v-if="editMeetingRoom.type === MEETING_ROOM_TYPE.PRIVATE"
              style="margin-bottom: 13px;"
          >
            <div style="margin-left: 100px; display: inline-block; margin-right: 40px;">
              <!-- <input
                  type="radio"
                  v-model="editMeetingRoom.type"
                  :value="MEETING_ROOM_TYPE.PRIVATE"
              /> -->
              <i class="inputSpace">业主/租客</i>
            </div>
            <CompanySelect
                :default-tenant-id="editMeetingRoom.lesseeId"
                :isMeetingRoomUse="true"
                :lesseeCompanyName="editMeetingRoom.lesseeCompanyName"
                class="leftFloat"
                style="width: 400px; display: inline-block;"
                @change-lesse-info="changeLesseInfo"
            ></CompanySelect>
          </div>
          <div
              v-if="editMeetingRoom.type === MEETING_ROOM_TYPE.PUBLIC"
              style="margin-bottom: 13px;"
          >
            <span class="lableBox">价格</span>
            <i class="price_">
              <input
                  v-model="editMeetingRoom.price"
                  placeholder="1-99999"
                  style="border-radius: 4px; border: 1px solid #999; width: 130px; padding-left: 10px; height: 40px;"
                  type="number"
              />
              元 / 小时
            </i>
          </div>
          <div style="margin-bottom: 13px;">
            <span class="lableBox">所属房间</span>
            <div style="display: inline-block; position: relative;">
              <CSSelect
                  height="40px"
                  iWidth="36px"
                  style="width: 250px;"
              >
                <div
                    :class="['room-select', {unselect: editMeetingRoom.floorRoomName === ''}]"
                    @click.stop="openRoomsPanel"
                >
                  {{
                    editMeetingRoom.floorRoomName !== '' ? (editMeetingRoom.buildingName + '-' + editMeetingRoom.floorRoomName) : '请选择'
                  }}
                </div>
              </CSSelect>
              <TreePanel v-if="roomsPanelVisible" style="z-index: 999"
              >
                <CSTree :tree="roomList" @change="changeCheckedRoom"/>
              </TreePanel>
            </div>
          </div>
          <div style="margin-bottom: 13px;">
            <span class="lableBox">会议室位置</span>
            <i>
              <input
                  v-model="editMeetingRoom.location"
                  placeholder="限30个字"
                  style="border-radius: 4px; border: 1px solid #999; width: 400px; padding-left: 10px; height: 40px;"
                  type="text"
              />
            </i>
          </div>
          <div style="margin-bottom: 13px;display: flex; align-items: center;">
            <span class="lableBoxImg">会议室照片</span>
            <div
                style="
                                display: inline-block;
                                margin-bottom: 0;
                                height: 85px;
                            "
            >
              <div
                  v-for="(src, imgIndex) in editMeetingRoom.photos"
                  :key="src"
                  class="room-photo"
                  style="width: 80px;
                                    height: 80px;
                                    border-radius: 4px;
                                    display: inline-block;
                                    vertical-align: top;
                                    position: relative;
                                    margin-right: 10px;
                                    margin-top: 0 !important;"
              >
                <img :src="src"/>
                <i
                    class="iconclose"
                    @click="deleteMeetingRoomPhotos(imgIndex)"
                ></i>
              </div>
              <div
                  v-if="editMeetingRoom.photos.length < 5"
                  class="upload-photo"
                  @click="triggerChoosePhoto"
              >
                <div class="text-center" style="top: 35px">
                  <img
                      alt=""
                      src="../../assets/upload.png"
                      style="margin: 5px auto"
                  />
                  <p style="color: #999">上传照片</p>
                </div>
              </div>
              <input
                  id="uploadStaffPhoto"
                  accept="images/*"
                  class="file"
                  hidden
                  type="file"
                  @change="chooseStaffPhoto($event)"
              />
            </div>
          </div>
          <div style="margin-bottom: 13px;">
            <span class="lableBox">会议室使用时间</span>
            <CSSelect
                height="40px"
                iWidth="36px"
                style="margin-right: 10px;"
                width="120px"
            >


              <select
                  v-model="editMeetingRoom.availableTimeStart"
                  :style="{
                                    color:
                                        editMeetingRoom.availableTimeStart != ''
                                            ? '#000'
                                            : '#999',
                                }"
                  aria-placeholder="qq"
              >
                <option value="">请选择</option>
                <template v-for="n in 23">
                  <option
                      v-if="n >= 6"
                      :key="n"
                      :disabled="
                                            editMeetingRoom.availableTimeStart !=
                                                '' &&
                                            n >=
                                                editMeetingRoom.availableTimeEnd - 0
                                        "
                      :value="prefixZero(n)"
                  >
                    {{ prefixZero(n) }}
                  </option>
                </template>
              </select>
            </CSSelect>
            <span style="margin: 0 5px">-</span>
            <CSSelect
                height="40px"
                iWidth="36px"
                style="margin-left: 10px;"
                width="120px"
            >
              <select
                  v-model="editMeetingRoom.availableTimeEnd"
                  :style="{
                                    color:
                                        editMeetingRoom.availableTimeStart != ''
                                            ? '#000'
                                            : '#999',
                                }"
                  style="height: 40px; width: 120px"
              >
                <option value="">请选择</option>
                <template v-for="n in 23">
                  <option
                      v-if="n >= 6"
                      :key="n"
                      :disabled="
                                            n <=
                                            editMeetingRoom.availableTimeStart - 0
                                        "
                      :value="prefixZero(n)"
                  >
                    {{ prefixZero(n) }}
                  </option>
                </template>
              </select>
            </CSSelect>
          </div>
          <div v-if="editMeetingRoom.type === MEETING_ROOM_TYPE.PUBLIC">
            <span class="lableBox">咨询电话</span>
            <input
                v-model="editMeetingRoom.phone"
                maxlength="11"
                placeholder="限11位数字"
                style="border-radius: 4px; border: 1px solid #999; width: 400px; padding-left: 10px; height: 40px;"
                type="text"
            />
          </div>
        </div>
      </CSDialog>
    </div>

    <CSDialog
        :dialogVisible="commonModal.visible"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="commonModal.onCancel"
        @onConfirm="commonModal.onOk"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; color: #000; text-align: center"
      >
        {{ commonModal.title }}
      </div>
    </CSDialog>


    <MeetingRoomPreview
        :company="lesseeCompanyName"
        :meetingRoomInfo="previewMeetingRoomParams"
        :visible="meetingRoomPreviewVisible || lookMeetingRoomModalVisible"
        @closeModal="closeMeetingRoomView"
    >
      <template v-if="meetingRoomPreviewVisible" v-slot:btnGroup>
        <div class="text-center" style="padding-top: 10px">
          <button
              class="btn btn-primary mr-5"
              style="width: 180px; height: 40px"
              @click="meetingRoomPreviewVisible = false"
          >
            上一步
          </button>
          <button
              class="btn btn-primary"
              style="width: 180px; height: 40px"
              @click.stop="addMeetingRoom"
          >
            确认
          </button>
        </div>
      </template>
      <template v-else slot="btnGroup">
        <div class="text-center" style="padding-top: 10px;">
          <button
              class="btn"
              style="width: 80px; height: 40px; font-size: 24px; background-color: #1ab394; color: #fff; line-height: 20px; box-shadow: 0px 5px 10px 0px rgba(26, 179, 148, 0.3);"
          >
            关闭
          </button>
        </div>
      </template>
    </MeetingRoomPreview>

    <!-- <Pagination></Pagination> -->
  </div>
</template>

<script>
import CompanySelect from "@/components/CompanySelect.vue";
import MeetingRoomPreview from "@/components/phonePreview/MeetingRoomPreview";
import Pagination from "@/components/Pagination";
import CSSelect from "@/components/common/CSSelect";
import TreePanel from "@/components/common/TreePanel";
import CSTree from "@/components/common/CSTree";
import CSDialog from "@/components/common/CSDialog";
import dayjs from "dayjs";
import {
  addMeetingRoomUrl,
  adminQueryMeetingRoomByIdUrl,
  adminQueryMeetingRoomUrl,
  changeEnableMeetingRoomUrl,
  deleteMeetingRoomUrl,
  queryBuildingUrl,
  queryMeetingRoomDetailUrl,
  queryRoomRentSimplifyUrl,
  queryTenantUrl,
  updateMeetingRoomUrl,
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";
import CSTabBar from "@/components/common/CSTabBar";

const MEETING_ROOM_TYPE = {
  PUBLIC: 2,
  PRIVATE: 1,
};
export default {
  props: {},
  components: {
    CSTabBar,
    CSTable,
    CompanySelect,
    MeetingRoomPreview,
    Pagination,
    CSSelect,
    CSTree,
    TreePanel,
    CSDialog,
  },
  data() {
    return {
      roomsPanelVisible: false,
      roomList: [],
      tenantList: [],
      MEETING_ROOM_TYPE,
      formInline: {
        user: "",
        region: "",
      },

      //  会议室预览用
      previewMeetingRoomParams: {
        name: "", //必填 会议室名称
        regionId: this.$vc.getCurrentRegion().communityId, //必填 区域id
        lesseeId: null, //选填 租客id
        buildingId: "", //必填 所在楼id
        buildingName: "", //必填 所在楼名称
        location: "", //必填 会议室所在位置
        phone: "", //选填 咨询电话
        type: MEETING_ROOM_TYPE.PUBLIC, //必填 1.私有;2.公有
        capacity: "", //必填 容纳人数
        availableTimeStart: "", //必填 可预约开始时间
        availableTimeEnd: "", //必填 可预约结束时间
        photos: [], //必填 会议室照片
        price: "", // 每小时价格 单位分
      },
      commonModal: {
        visible: false,
        title: "",
        onCancel: () => {
          this.commonModal.visible = false;
        },
        onOk: () => {
        },
      },
      listQuery: {
        type: MEETING_ROOM_TYPE.PUBLIC,
        reservationDate: dayjs().format("YYYY-MM-DD"),
        lesseeId: "",
        regionId: this.$vc.getCurrentRegion().communityId,
      },
      minCapacity: "",
      maxCapacity: "",
      meetingRoomPreviewVisible: false, // 会议室预览
      lookMeetingRoomModalVisible: false, // 查看会议室
      lesseeCompanyName: "",
      meetingRoomModal: false, // 添加/编辑会议室弹窗
      // 添加/编辑会议室里面的数据
      editMeetingRoom: {
        name: "", //必填 会议室名称
        regionId: this.$vc.getCurrentRegion().communityId, //必填 区域id
        lesseeId: "", //选填 租客id
        buildingId: "", //必填 所在楼id
        buildingName: "", //必填 所在楼名称
        floorRoomId: '',
        floorRoomName: '',
        location: "", //必填 会议室所在位置
        phone: "", //选填 咨询电话
        type: MEETING_ROOM_TYPE.PUBLIC, //必填 1.私有;2.公有
        capacity: "", //必填 容纳人数
        availableTimeStart: "", //必填 可预约开始时间
        availableTimeEnd: "", //必填 可预约结束时间
        photos: [], //必填 会议室照片
        price: "", // 每小时价格 单位分
      },
      total: 11,
      orderState: {
        [MEETING_ROOM_TYPE.PUBLIC]: "共享会议室",
        [MEETING_ROOM_TYPE.PRIVATE]: "私有会议室",
      },
      buildings: [],
      // 获取到的页面数据
      list: [],
      workOrderQueryParam: {
        startTime: new Date(dayjs().add(30, "day").valueOf()),
        endTime: new Date(),
        regionId: "", //必填 区域id
        type: 1, //必填 类型 1.私有会议室;2.共享会议室;
        date: "", //必填 查询日期 默认当天
        // "searchCompanyName":"",//选填 搜索公司名称
        roomId: 1, //选填 会议室id
      },

      // 控制退款弹出框显示与隐藏
      refundBox: false,
      // hot小红点显示与隐藏
      hidden: true,
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  created() {
    // this.handleFilter();
    window.addEventListener("keydown", this.handleFilterDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleFilterDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.handleFilterDown);
  },
  mounted() {
    this.changeTabBar(this.workOrderQueryParam.state);
    this.getBuildings();
    this.getTenantList();
    this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
      this.pageParams.pageNo = _currentPage;
      this.getMeetingRoomList(_currentPage);
    });
  },
  methods: {
    handleFilterDown(e) {
      if (e.keyCode == 13) {
        this.handleFilter();
      }
    },
    async openRoomsPanel() {
      if (this.editMeetingRoom.id) {
        return;
      }
      await this.getRoomList(this.editMeetingRoom.floorRoomId);
      this.roomsPanelVisible = true;
    },
    /**
     * 更改选中的房间
     * @param {Object} checkBoxInfo 选中的信息
     * */
    changeCheckedRoom(checkBoxInfo) {
      const {key, source} = checkBoxInfo,
          checkbox = source[key];
      this.editMeetingRoom.floorRoomId = checkbox.id;
      this.editMeetingRoom.floorRoomName = checkbox.name;
      this.editMeetingRoom.buildingId = checkbox.building.id;
      this.editMeetingRoom.buildingName = checkbox.building.name;
      this.roomsPanelVisible = false;
    },
    /**
     * 获取房间列表
     * @param {Number} checkRoomId 选中的房间id
     * */
    getRoomList(checkRoomId) {
      return this.$fly.post(queryRoomRentSimplifyUrl, {
        regionId: this.$vc.getCurrentRegion().code,
        buildingId: "",
        roomNo: "",
        state: 1,
      })
          .then(res => {
            if (res.code !== 0) {
              return;
            }
            const rooms = res.data.datas.map(building => {
              building.id = building.buildingId;
              building.name = building.buildingName;
              building.showCheckbox = false;
              building.children = building.floors.map((floor, floorIndex) => {
                floor.id = floorIndex;
                floor.name = floor.floor;
                floor.showCheckbox = false;
                floor.children = floor.rooms.map(room => {
                  room.id = room.roomId;
                  room.name = room.roomNo;
                  room.floor = {
                    id: floorIndex,
                    name: floor.floor,
                  };
                  room.building = {
                    id: building.buildingId,
                    name: building.buildingName,
                  };
                  room.checked = checkRoomId === room.id;
                  room.allowCheck = checkRoomId === undefined || checkRoomId !== room.id;
                  // room.showCheckbox = checkRoomId === undefined || checkRoomId === room.id;
                  return room;
                })
                return floor;
              })
              return building;
            })
            this.roomList = rooms;
            return rooms;
          })
    },
    openAddMeetingRoom() {
      this.meetingRoomModal = true;
      this.minCapacity = '';
      this.maxCapacity = '';
      this.editMeetingRoom = {
        name: "", //必填 会议室名称
        regionId: this.$vc.getCurrentRegion().communityId, //必填 区域id
        lesseeId: "", //选填 租客id
        buildingId: "", //必填 所在楼id
        buildingName: "", //必填 所在楼名称
        floorRoomId: '',
        floorRoomName: '',
        location: "", //必填 会议室所在位置
        phone: "", //选填 咨询电话
        type: MEETING_ROOM_TYPE.PUBLIC, //必填 1.私有;2.公有
        capacity: "", //必填 容纳人数
        availableTimeStart: "", //必填 可预约开始时间
        availableTimeEnd: "", //必填 可预约结束时间
        photos: [], //必填 会议室照片
        price: "", // 每小时价格 单位分
      };
    },
    //  获取租客列表
    getTenantList(pageNo = 1, pageSize = 50) {
      const params = {
        regionId: this.$vc.getCurrentRegion().communityId,
        companyName: "",
        keyword: "",
      };
      if (params.companyName === "") {
        delete params.companyName;
      }
      this.$fly.post(queryTenantUrl, {
        ...params,
        pageNo,
        pageSize,
      }).then((res) => {
        this.tenantList = res.data.datas;
      });
    },
    // 查看会议室详情
    async lookMeetingRoom(type, roomId, roomName, lesseeCompanyName) {
      const meetingRooms = await this.queryMeetingRoomDetail(
          type,
          roomId
      );
      if (!meetingRooms || meetingRooms.length == 0) {
        this.$vc.message("获取会议室详情失败");
        return;
      }
      this.$vc.copyObject(meetingRooms[0], this.previewMeetingRoomParams);
      this.previewMeetingRoomParams.name = roomName
      this.previewMeetingRoomParams.type = type
      this.previewMeetingRoomParams.companyName = lesseeCompanyName;
      this.lookMeetingRoomModalVisible = true;
    },
    // 关闭预览弹窗
    closeMeetingRoomView() {
      this.meetingRoomPreviewVisible = false;
      this.lookMeetingRoomModalVisible = false;
    },
    /**
     * 查询会议室详情
     * @param {Number} type
     * @param {Number} roomId
     *  */
    async queryMeetingRoomDetail(type, roomId) {
      return this.$fly
          .post(queryMeetingRoomDetailUrl, {
            type,
            date: dayjs().format("YYYY-MM-DD"),
            regionId: this.$vc.getCurrentRegion().communityId,
            searchCompanyName: "",
            roomId,
            /* lastId: 0,
            pageSize: 1, */
          })
          .then((res) => {
            if (res.data.photos) {
              res.data.photos = JSON.stringify(res.data.photos);
            }
            return res.data;
          });
    },
    // 预览编辑的会议室信息
    previewEditMeetingRoomInfo() {
      let params = {...this.editMeetingRoom};
      if (params.type === MEETING_ROOM_TYPE.PUBLIC) {
        params.lesseeId = null;
      } else {
        params.price = null;
      }
      params.capacity = this.minCapacity + "-" + this.maxCapacity;

      params.availableTimeStart = params.availableTimeStart + ":00:00";
      params.availableTimeEnd = params.availableTimeEnd + ":00:00";

      this.meetingRoomPreviewVisible = true;
      this.previewMeetingRoomParams = params;
    },
    /**
     * 删除会议室房间下标
     * @param {Number} index 图片下标
     *
     *  */
    deleteMeetingRoomPhotos(index) {
      this.editMeetingRoom.photos.splice(index, 1);
    },
    /**
     * 编辑会议室
     * @param {Object} meetingRoomInfo 会议室信息
     *
     *  */
    changeMeetingRoom(meetingRoomInfo) {
      this.$fly
          .post(adminQueryMeetingRoomByIdUrl, {
            regionId: this.$vc.getCurrentRegion().communityId, //必填 区域id
            roomId: meetingRoomInfo.id,
          })
          .then(async res => {
            const data = res.data;
            this.$vc.copyObject(data, this.editMeetingRoom);
            this.editMeetingRoom.id = meetingRoomInfo.id;
            if (data.photos) {
              this.editMeetingRoom.photos = JSON.parse(data.photos);
            }
            if (data.capacity) {
              this.minCapacity = data.capacity.split("-")[0];
              this.maxCapacity = data.capacity.split("-")[1];
            }
            this.editMeetingRoom.availableTimeStart = data.availableTimeStart.substr(
                0,
                2
            );
            this.editMeetingRoom.availableTimeEnd = data.availableTimeEnd.substr(
                0,
                2
            );

            this.editMeetingRoom.lesseeCompanyName = meetingRoomInfo.lesseeCompanyName;
            if (this.editMeetingRoom.price) {
              this.editMeetingRoom.price = meetingRoomInfo.price;
            }
            const buildingList = await this.getRoomList(data.floorRoomId);
            buildingList.forEach(building => {
              if (building.id === data.buildingId) {
                this.editMeetingRoom.buildingName = building.name;
              }
            })
            this.meetingRoomModal = true;
            // this.changeEditMeetingRoomBuilding();
          });
    },
    // 更改会议室内容
    updateMeetingRoom() {
      let params = {
        id: "",
        name: "",
        capacity: "",
        price: "",
        photos: "",
        availableTimeStart: "",
        availableTimeEnd: "",
        phone: "",
      };
      this.$vc.copyObject(this.editMeetingRoom, params);
      params.capacity = this.minCapacity + "-" + this.maxCapacity;
      params.availableTimeStart = params.availableTimeStart + ":00:00";
      params.availableTimeEnd = params.availableTimeEnd + ":00:00";
      params.photos = JSON.stringify(params.photos);
      this.$fly.post(updateMeetingRoomUrl, params).then((res) => {
        if (res.code != 0) {
          this.meetingRoomPreviewVisible = false;
          return;
        }
        this.$vc.toast("修改成功");
        this.meetingRoomPreviewVisible = false;
        this.getMeetingRoomList();
        this.meetingRoomModal = false;
      });
    },
    /**
     * 删除会议室
     * @param {Number} roomId 会议室id
     *
     *  */
    deleteMeetingRoom(roomId) {
      this.commonModal.visible = true;
      this.commonModal.title = "确定删除吗?";
      this.commonModal.onOk = () => {
        this.$fly
            .post(deleteMeetingRoomUrl, {
              roomId,
            })
            .then((res) => {
              if (res.code != 0) {
                return;
              }
              this.$vc.toast("操作成功");
              this.commonModal.visible = false;
              this.getMeetingRoomList();
            });
      };
    },
    /**
     * 改变房间上下架状态
     * @param {Number} roomId  会议室id
     * @param {Number} isEnable 0:下架 1:上架
     *  */
    changeMeetingRoomEnableState(roomId, isEnable) {
      this.commonModal.visible = true;
      this.commonModal.title = `确定${isEnable ? "上" : "下"}架吗?`;
      this.commonModal.onOk = () => {
        this.$fly
            .post(changeEnableMeetingRoomUrl, {
              roomId,
              isEnable,
            })
            .then((res) => {
              if (res.code != 0) {
                return;
              }
              this.$vc.toast("操作成功");
              this.commonModal.visible = false;
              this.getMeetingRoomList();
            });
      };
    },
    //  调起选择文件
    triggerChoosePhoto() {
      $("#uploadStaffPhoto").trigger("click");
    },
    // 选择文件后处理
    async chooseStaffPhoto(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        if (file.size > 1024 * 1024 * 5) {
          this.$vc.toast("图片大小不能超过 5MB!");
          return false;
        }
        let formData = new FormData(); //新建FormData对象
        formData.append("files", photoFiles[0]);
        formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
        formData.append("compress", "true");
        const photo = await this.Constants.uploadFileOne(formData);
        if (photo) {
          event.target.value = ""
          this.editMeetingRoom.photos.push(this.Constants.BUCKET_NAMES.IMG.visitUrl + photo);
        }
      }
    },
    /**
     * 修改选中的建筑物
     *
     *  */
    changeEditMeetingRoomBuilding() {
      this.buildings.forEach((val) => {
        if (val.code == this.editMeetingRoom.buildingId) {
          this.editMeetingRoom.buildingName = val.name;
        }
      });
    },
    /**
     * 个位数前缀补零
     * @param {Number} num
     *  */
    prefixZero(num) {
      return num.toString().padStart(2, "0");
    },
    changePayStateTabBar(state) {
      this.listQuery.type = state;
      this.handleFilter();
      this.hidden = false;
    },
    handleFilter() {
      this.getMeetingRoomList();
    },
    // 获取会议室列表
    getMeetingRoomList(pageNo = this.pageParams.pageNo, pageSize = 10) {
      const params = {...this.listQuery};
      if (params.type === MEETING_ROOM_TYPE.PUBLIC) {
        delete params.lesseeId;
      }
      params.pageNo = pageNo;
      params.pageSize = pageSize;
      this.$fly.post(adminQueryMeetingRoomUrl, params).then((res) => {
        if (pageNo === 1) {
          this.$vc.emit(this.$route.path, "pagination", "init", {
            total: res.data.total,
            currentPage: pageNo,
            pageSize: res.data.total ? pageSize : res.data?.datas?.length || 0,
          });
        }
        this.list = res.data?.datas || [];
      });
    },
    // 时间函数
    changeTabBar(state) {
      this.workOrderQueryParam.lastTimeOrderIds = [null];
      this.workOrderQueryParam = {
        startTime: new Date(
            dayjs(
                dayjs().subtract(0, "day").format("YYYY-MM-DD") +
                "00:00:00"
            ).valueOf()
        ),
        regionId: this.$vc.getCurrentRegion().communityId,
        type: "",
        state: state,
      };
      if (state == 1) {
        this.workOrderQueryParam.origin = 2;
      }
      this.getMeetingRoomList();
    },
    // 获取楼栋列表
    getBuildings() {
      this.$fly
          .post(queryBuildingUrl, {
            regionCode: this.$vc.getCurrentRegion().communityId,
            name: "",
            code: "",
            id: "",
          })
          .then((res) => {
            this.buildings = res.data;
          });
    },
    // 添加会议室
    addMeetingRoom() {

      if (!Number.isInteger(this.editMeetingRoom.price - 0)) {
        this.$vc.toast("价格仅限输入整数");
        return;
      }
      if (this.editMeetingRoom.type === MEETING_ROOM_TYPE.PUBLIC && (this.editMeetingRoom.price === '' || this.editMeetingRoom.price == 0)) {
        this.$vc.toast("请输入会议室价格");
        return;
      }
      if (this.editMeetingRoom.id) {
        this.updateMeetingRoom();
        return;
      }
      let params = {...this.editMeetingRoom};
      if (params.type === MEETING_ROOM_TYPE.PUBLIC) {
        params.lesseeId = null;
      } else {
        params.price = null;
      }
      params.capacity = this.minCapacity + "-" + this.maxCapacity;

      params.availableTimeStart = params.availableTimeStart + ":00:00";
      params.availableTimeEnd = params.availableTimeEnd + ":00:00";
      params.photos = JSON.stringify(params.photos);

      this.$fly.post(addMeetingRoomUrl, params).then((res) => {
        if (res.code != 0) {
          this.meetingRoomPreviewVisible = false;
          return;
        }
        this.$vc.toast("操作成功");
        this.meetingRoomPreviewVisible = false;
        this.meetingRoomModal = false;
        this.getMeetingRoomList();
      });
    },
    // 私有会议室
    changeLesseInfo(lesseeInfo) {
      this.editMeetingRoom.lesseeId = lesseeInfo.id;
      this.lesseeCompanyName = lesseeInfo.companyName;
    },
  },
};
</script>

<style scoped>
.el-dialog__header {
  /* margin: 0; */
  padding: 0;
  height: 0;
}

.el-dialog__body {
  padding: 5px 0 0 30px;
  color: #000000;
}

.el-dialog__footer {
  padding: 30px 0 30px 20px;
  border-top: 2px solid #ccc;
  text-align: center;
}

.twiceHint .el-dialog__footer {
  padding-top: 10px;
  border: none;
}

img {
  position: relative;
  margin-right: 20px;
}

.aa .el-dialog__body,
.aa .el-dialog {
  padding: 0;
  background: none;
  box-shadow: none;
}

.amendstyle .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 130px;
}

.cs-style input[type="checkbox"] {
  height: 16px;
}
</style>

<style scoped>
.el-dialog .upload-photo,
.el-dialog .room-photo {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin-right: 10px;
  margin-top: 0 !important;
}

.room-photo {
  border: 1px solid #999999;
}

.room-photo img {
  width: 100%;
  height: 100%;
}

.upload-photo {
  text-align: center;
  border: 1px dashed #999999;
  font-size: 14px;
  margin-bottom: 0;
}

.upload-photo div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.upload-photo img {
  width: 34px;
  height: 27px;
  margin-bottom: 13px;
}

.el-table-column {
  text-align: center;
}

i {
  font-style: normal;
}

.el-tabs {
  margin: 20px 0;
}

.btn-secondary {
  margin: 20px 0;
}

.el-table {
  text-align: center;
}

.el-table-column {
  text-align: center;
}

.form-group {
  display: inline;
  margin-right: 20px;
}

.el-card {
  margin-bottom: 20px;
}

.line {
  margin-right: 20px;
}

.form-tip {
  margin-left: 10px;
}

.dialog-footer .el-button {
  margin-right: 130px;
}

.el-dialog span {
  margin-right: 20px;
}

.el-dialog div {
  margin: 10px 0;
}

.el-dialog .remarks {
  margin-left: 14px;
}

.col-lg-12 button {
  border: 0;
}

.item {
  position: relative;
  left: 55px;
  top: -10px;
}

.el-button:hover {
  color: #6a8bf6;
  text-decoration: underline;
}

.success {
  width: 50px;
  height: 30px;
  border-radius: 5px;
  background-color: #18a689;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
}

.adddialog {
  background-color: #1ab394;
}

.adddialog:hover {
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
}

.typeinput {
  width: 100px;
  display: inline-block;
  margin-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-group span {
  margin-right: 10px;
}

.dropdown-menu a {
  z-index: 99999;
}

.dropdown-menu {
  z-index: 99999 !important;
}

.dialog-footer .el-button[data-v-7e897d31] {
  margin-right: 60px;
}

.table {
  text-align: center !important;
}

.el-card__body {
  padding: 0 !important;
}

input {
  width: 200px;
  height: 30px;
}

.inputwidth input {
  width: 80px;
}

.el-dialog div {
  margin-bottom: 15px;
}

.lableBox {
  display: inline-block;
  width: 210px !important;
  height: 20px;
  text-align: right;
  margin-right: 40px;
}

.lableBox + i input:disabled {
  cursor: not-allowed;
}

.lableBoxImg {
  display: inline-block;
  width: 210px !important;
  text-align: right;
  margin-right: 40px;


}

.lableBox img {
  margin-right: 20px !important;
}

.el-icon-camera {
  position: absolute;
  top: 10px;
  left: 28px;
  font-size: 20px;
}

.uploadPictures {
  position: absolute;
  width: 70px;
  top: -25px;
  left: 6px;
  overflow: hidden;
}

.line {
  margin: 0 20px;
}

.phoneBackground {
  background: url(../../assets/iphone6.png) no-repeat;
  width: 500px;
  height: 850px;
  background-size: 100% 100%;
  background-position: center center;
  margin: 0 auto;
}

.contentarea {
  position: absolute;
  top: 95px;
  left: 30px;
  width: 442px;
  height: 640px;
  background-color: #fff;
}

.price_ input {
  width: 65px;
}

/* input 单选框样式 */
input[type="radio"] {
  width: 20px;
  height: 16px;
  cursor: pointer;
  position: relative;

}

input[type="radio"]:after {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  content: " ";
  background-color: #fff;
  color: #fff;
  display: inline-block;
  visibility: visible;
  border-radius: 3px;
  border: 1px solid #999999;
  font-weight: bold;
  text-align: center;
}

input[type="radio"]:checked:after {
  content: "✓";
  font-size: 13px;
  background-color: #1ab394;
}

.priceBox {
  padding-left: 150px;
}

.bb .leftFloat {
  margin-left: 135px;
}

.inputSpace {
  margin-left: 5px !important;
  vertical-align: bottom;
}

.typeinput {
  border: 1px solid #999;
}

.col-lg-12 > .btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.col-lg-12 > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.el-dialog__footer {
  padding: 15px 30px;
  border-top: 2px solid #ccc;
  text-align: center;
  box-sizing: border-box;
}

.yellow_ {
  width: 80px;
  height: 40px;
  background: #ff9f00;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  border: none;
  color: #fff;
  margin-right: 20px;
}

.confirm {
  width: 80px;
  height: 40px;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border-radius: 6px;
  border: none;
  color: #fff;
}

.deleteHint {
  text-align: center;
  font-size: 18px;
  padding-right: 20px;
}

select {
  border-radius: 3px;
  border: 1px solid #999;
}

.el-dialog input {
  border-radius: 3px;
  border: 1px solid #999;
  padding-left: 5px;
}

.el-dialog i {
  display: inline-block;
  position: relative;
}

.table > tbody > tr > td {
  padding: 15px 8px !important;
}

table a {
  text-decoration: underline;
}

.room-select {
  width: 215px;
  overflow: hidden;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  white-space: nowrap;

}

.room-select.unselect {
  color: #999;
}

/*
.room-select.unselect span {
    height: 30px;
    line-height: 30px;
    background: #1ab394;
    padding: 0 10px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    border-radius: 15px;
}*/

.iconclose {
  display: inline-block;
  position: absolute !important;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  background: url(../../assets/delete.png) no-repeat;
  background-size: 80%;
}
</style>
